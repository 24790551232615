import React, { useEffect, useRef } from 'react';
import { black_icons } from '../../icons';

function ImageUploader({ show, onHide, canvasRef, selectedTool }) {

    const fileInputRef = useRef(null);
    const uploadAreaRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (show && !event.target.closest('.mdl-dia')) {
                onHide();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);

        };
    }, [show, onHide]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.match(/^image\/(jpg|jpeg|gif|png|webp)$/i)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (selectedTool === "Graphic") {
                        canvasRef.current.addImage(e.target.result);
                    } else {
                        canvasRef.current.addLogo(e.target.result);
                    }
                };
                reader.readAsDataURL(file);
                onHide();
            }
        }
        event.target.value = '';

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        uploadAreaRef.current.classList.add('drag-over');
    }

    const handleDragLeave = () => {
        uploadAreaRef.current.classList.remove('drag-over');
    }

    const handleDrop = (event) => {
        event.preventDefault();
        uploadAreaRef.current.classList.remove('drag-over');
        const file = event.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            handleImageUpload({ target: { files: [file] } });
        }
    }


    return (
        <div className="mdl" style={{ display: show ? 'flex' : 'none' }}>
            <input type="file" accept="image/*" onChange={handleImageUpload} ref={fileInputRef} style={{ display: 'none' }} />
            <div className="mdl-dia mdl-dia-upload">
                <div
                    className="upload-area"
                    ref={uploadAreaRef}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}>
                    <button className="close-button upload-close-btn" onClick={onHide}><black_icons.closeIcon /></button>
                    <div className='mdl-tit text-gray-900'>DRAG YOUR IMAGE HERE</div>
                    <button className="upload-button" onClick={() => fileInputRef.current.click()}>OR CLICK HERE</button>
                    <p className='secondary-text'>JPG / JPEG / GIF / PNG / WEBP</p>
                </div>
            </div>
        </div>
    );
}

export default ImageUploader;