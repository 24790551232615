import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { Texture, Mesh, Vector3, HemisphericLight, MeshBuilder, ArcRotateCamera, AssetsManager, StandardMaterial, Color3, Color4, DynamicTexture, Engine, RenderTargetTexture } from "@babylonjs/core";
import SceneComponent from "./SceneComponent";
import "@babylonjs/loaders/glTF";


const getParentSize = parent => {
    const sizes = parent.getHierarchyBoundingVectors()
    const size = {
        x: sizes.max.x - sizes.min.x,
        y: sizes.max.y - sizes.min.y,
        z: sizes.max.z - sizes.min.z
    }
    return size
}

const DesignerView = ({ modelUrl, canvasState, viewMode, selectedScene, envelopeDimension, settings, setModelLoaded }, ref) => {

    const canvasRef = useRef(null);
    var sceneRef = useRef(null)
    const currentModel = useRef(null);
    const assetsManagerRef = useRef();
    const [iEnvelopeGrid, setIEnvelopeGrid] = useState([]);


    const [sceneReady, setSceneReady] = useState(false);
    const [iEnvelope, setIEnvelope] = useState(null);
    const [iParavent, setIParavent] = useState(null);
    const [iScoop, setIScoop] = useState(null);
    const [iTop, setITop] = useState(null);

    useImperativeHandle(ref, () => ({
        downloadPictures: async (fileName = "file", selectedViews = [0]) => {
            takePictures(fileName, selectedViews, true);
        },
        getPictures: (selectedViews = [0]) => {
            return takePictures("temp", selectedViews, false)
        }
    }))

    const getCoordinatesFromId = ((id) => {
        const parts = id.split('-');
        if (parts.length === 2) {
            const rowStr = parts[0].startsWith('V') ? parts[0].slice(1) : parts[0];
            const colStr = parts[1].startsWith('V') ? parts[1].slice(1) : parts[1];
            return { row: parseInt(rowStr), col: parseInt(colStr) - 1 };
        }
        return null;
    })

    const takePictures = (fileName, selectedViews, download = true, directoryHandle = null) => {
        if (sceneRef.current && currentModel.current) {

            const cameraPositions = [
                { position: sceneRef.current.activeCamera.position, target: sceneRef.current.activeCamera.target, rotation: sceneRef.current.activeCamera.rotation, name: 'active-camera' },
                { position: new Vector3(0, 0, 200), target: new Vector3(0, 0, 0), rotation: new Vector3(0, 0, 0), name: 'back' },
                { position: new Vector3(0, 0, -200), target: new Vector3(0, 0, 0), rotation: new Vector3(0, Math.PI, 0), name: 'front' },
                { position: new Vector3(200, 0, 0), target: new Vector3(0, 0, 0), rotation: new Vector3(0, Math.PI / 2, 0), name: 'left' },
                { position: new Vector3(-200, 0, 0), target: new Vector3(0, 0, 0), rotation: new Vector3(0, -Math.PI / 2, 0), name: 'right' },
                { position: new Vector3(0, 200, 0), target: new Vector3(0, 0, 0), rotation: new Vector3(Math.PI / 2, 0, 0), name: 'top' },
                { position: new Vector3(0, -150, 0), target: new Vector3(0, 0, 0), rotation: new Vector3(-Math.PI / 2, 0, 0), name: 'bottom' },
            ];

            const filteredCameraPositions = cameraPositions.filter((position, index) => selectedViews.includes(index));

            const width = download ? 1190 : 1684;
            const height = 1684;

            const tempCamera = new ArcRotateCamera("TempCamera", 0, 0, 1, Vector3.Zero(), sceneRef.current);
            tempCamera.maxZ = 10000;

            const rtt = new RenderTargetTexture("tempRtt", {
                width: width,
                height: height,
                samples: 4,
            }, sceneRef.current, false, false);
            console.log(rtt.wrapU, rtt.wrapV)
            rtt.renderList.push(...currentModel.current.getChildMeshes(), sceneRef.current.getMeshByName("skyBox"));

            const imagePromises = filteredCameraPositions.map((position, index) => {
                if (index === 1) { rtt.renderList.pop() }
                return new Promise(resolve => {
                    const curentPosition = position;
                    tempCamera.position = curentPosition.position;
                    tempCamera.target = curentPosition.target;
                    tempCamera.rotation = curentPosition.rotation;

                    rtt.activeCamera = tempCamera;
                    rtt.render();

                    rtt.readPixels().then(data => {
                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        const context = canvas.getContext('2d');

                        const tempCanvas = document.createElement('canvas');
                        tempCanvas.width = width;
                        tempCanvas.height = height;
                        const tempContext = tempCanvas.getContext('2d');

                        const imageData = tempContext.createImageData(width, height);
                        imageData.data.set(data);
                        tempContext.putImageData(imageData, 0, 0);

                        context.translate(0, canvas.height);
                        context.scale(1, -1);
                        context.drawImage(tempCanvas, 0, 0);

                        resolve({ src: canvas.toDataURL("image/jpeg", 1), name: position.name });
                    });
                })

            });

            const picturesPromise = Promise.all(imagePromises)

            if (download) {
                const loadWatermarkPromise = new Promise(resolve => {

                    const watermarkImage = new Image();

                    watermarkImage.crossOrigin = "anonymous";
                    watermarkImage.src = settings.overlayUrl;

                    watermarkImage.src = settings.overlayUrl;
                    watermarkImage.onload = () => {
                        resolve(watermarkImage);
                    };
                });

                loadWatermarkPromise.then(watermarkImage => {
                    picturesPromise.then(imagesToReturn => {

                        const watermarkPromises = imagesToReturn.map(image => {
                            return new Promise(resolve => {
                                const canvas = document.createElement('canvas');
                                canvas.width = watermarkImage.width;
                                canvas.height = watermarkImage.height;
                                const context = canvas.getContext('2d');

                                const img = new Image();
                                img.onload = () => {
                                    context.drawImage(img, 0, 0, canvas.width, canvas.height);
                                    context.drawImage(watermarkImage, 0, 0);
                                    resolve({ src: canvas.toDataURL("image/jpeg", 1), name: image.name });
                                };
                                img.src = image.src;
                            });
                        });

                        Promise.all(watermarkPromises).then(watermarkedImages => {

                            downloadPictures(fileName, watermarkedImages, directoryHandle);
                            tempCamera.dispose();
                        });
                    });
                })
            } else {
                return picturesPromise.then(imagesToReturn => {
                    return imagesToReturn;
                }).finally(() => {
                    tempCamera.dispose();
                });
            }
        }
    };

    const downloadPictures = async (fileName, pictures, directoryHandle = null) => {

        const downloadPicture = async (picture) => {
            if (directoryHandle) {
                try {
                    const fileHandle = await directoryHandle.getFileHandle(`${fileName}_${picture.name}.jpeg`, { create: true });
                    const writable = await fileHandle.createWritable();
                    const response = await fetch(picture.src);
                    const blob = await response.blob();
                    await writable.write(blob);
                    await writable.close();

                } catch (error) {
                    console.error('Error during download:', error);
                }
            } else {

                const link = document.createElement('a');
                link.href = picture.src;
                link.download = `${fileName}_${picture.name}.jpeg`;
                link.click();

            }
        };

        await Promise.all(pictures.map(downloadPicture));
    };

    const applyCanvasState = (state) => {
        state.envelopeGrid.forEach((row, y) => {
            if (y === state.envelopeGrid.length - 1) {
                iTop.material.diffuseColor = Color3.FromHexString(row[0]);
            } else {
                if (y === 0) {
                    iScoop.material.diffuseColor = Color3.FromHexString(row[0]);
                }
                row.forEach((colorHex, x) => {
                    iEnvelopeGrid[y][x].material.diffuseColor = Color3.FromHexString(colorHex);
                })
            }
        })
        var texture = iEnvelope.material.opacityTexture;
        texture.clear();
        var paraventTexture = iParavent.material.opacityTexture;
        paraventTexture.clear();
        redrawCanvas(state.images, state.texts, state.paraventLogo);
        texture.update();
        paraventTexture.update();
        iEnvelope.material.needsUpdate = true;
        iParavent.material.needsUpdate = true;
    }

    const redrawCanvas = (images, texts, paraventLogo) => {
        if (iEnvelope) {
            const texture = iEnvelope.material.opacityTexture;
            const context = texture.getContext();
            const widthConversionScale = 4096 / envelopeDimension.width;
            const heightConversionScale = 4096 / (envelopeDimension.paraventRadius * 2);
            const imageLoadPromises = images.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = image.src;
                    img.onload = () => resolve(img);
                    img.onerror = () => {
                        console.error("Error loading image:", image.src);
                        resolve(null);
                    };
                });
            });

            Promise.all(imageLoadPromises)
                .then(loadedImages => {
                    loadedImages.forEach((img, index) => {
                        if (!img) return;
                        const image = images[index];
                        context.save();
                        context.translate(image.centerPointX * widthConversionScale, image.centerPointY * heightConversionScale);
                        context.scale(widthConversionScale, heightConversionScale);
                        context.rotate(image.angle * Math.PI / 180);
                        context.scale(image.scaleX, image.scaleY);
                        context.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
                        context.restore();
                    });

                    if (texts.length > 0) {
                        texts.forEach((text) => {
                            const fontString = `${text.fontStyle} ${text.fontWeight} ${text.fontSize}px ${text.fontFamily}`;

                            context.font = fontString;
                            context.fillStyle = text.fill;
                            context.textAlign = "left";
                            context.textBaseline = "top";

                            context.save();
                            context.translate(text.left * widthConversionScale, text.top * heightConversionScale);
                            context.scale(widthConversionScale, heightConversionScale);
                            context.rotate(text.angle * Math.PI / 180);
                            context.scale(text.scaleX, text.scaleY);

                            const lines = text.text.split('\n');
                            lines.forEach((line, lineIndex) => {
                                context.fillText(line, 0, lineIndex * text.lineHeight * text.fontSize);
                            });

                            context.restore();
                        });
                    }

                    texture.update();
                })
                .catch(error => {
                    console.error("Error loading images:", error);
                });
        }
        if (iParavent) {
            var paraventTexture = iParavent.material.opacityTexture;
            var paraventContext = paraventTexture.getContext();
            var widthConversionScaleParavent = 1024 / (envelopeDimension.paraventRadius * 2);
            var heightConversionScaleParavent = 1024 / (envelopeDimension.paraventRadius * 2);
            if (paraventLogo) {
                var paraventLogoLeft = paraventLogo.centerPointX - (envelopeDimension.width / 2 - envelopeDimension.paraventRadius);
                var paraventLogoTop = paraventLogo.centerPointY;
                const img = new Image();
                img.src = paraventLogo.src;
                img.onload = () => {
                    paraventContext.save();
                    paraventContext.translate(paraventLogoLeft * widthConversionScaleParavent, paraventLogoTop * heightConversionScaleParavent);
                    paraventContext.scale(widthConversionScaleParavent, heightConversionScaleParavent);
                    paraventContext.rotate(paraventLogo.angle * Math.PI / 180);
                    paraventContext.scale(paraventLogo.scaleX, paraventLogo.scaleY);
                    paraventContext.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
                    paraventContext.restore();
                    paraventTexture.update();
                }
            }
        }
    };


    useEffect(() => {
        if (canvasState && iScoop && iTop && iEnvelopeGrid.length > 0 && sceneReady) {
            applyCanvasState(canvasState)
        }

    }, [canvasState, iScoop, iEnvelopeGrid, iTop, sceneReady]);

    useEffect(() => {
        if (sceneReady && modelUrl) {
            var material = new StandardMaterial("modelMaterial", sceneRef.current);
            material.diffuseColor = new Color3(0.5, 0.5, 0.5);
            material.specularColor = new Color3(0.005, 0.005, 0.005);
            material.backFaceCulling = false;

            var texture = new DynamicTexture("envelope dynamic texture", 4096, sceneRef.current, true);
            var context = texture.getContext();

            // Flip the Y-axis
            // context.scale(1, -1); // Flip vertically
            // context.translate(0, -texture.getSize().height); // Adjust origin
            // context.scale(-1, 1);
            // context.translate(-texture.getSize().width, 0);

            // Pre-fill the texture with a default color (e.g., white)
            context.fillStyle = 'rgba(0, 0, 0, 0)';
            context.fillRect(0, 0, 4096, 4096);
            texture.update();

            var envelopeMaterial = new StandardMaterial("envelope material", sceneRef.current);

            var textureParavent = new DynamicTexture("paravent dynamic texture", 1024, sceneRef.current, true);
            var contextParavent = textureParavent.getContext();

            // Flip the X-axis (horizontal flip)
            // contextParavent.scale(-1, 1);
            // contextParavent.translate(-textureParavent.getSize().width, 0); // Adjust origin

            contextParavent.fillStyle = 'rgba(0, 0, 0, 0)';
            contextParavent.fillRect(0, 0, 1024, 1024);
            textureParavent.update();

            var paraventMaterial = new StandardMaterial("paravent material", sceneRef.current);

            envelopeMaterial.opacityTexture = texture;
            envelopeMaterial.diffuseTexture = texture;
            envelopeMaterial.diffuseTexture.hasAlpha = true;
            envelopeMaterial.specularColor = new Color3(0.01, 0.01, 0.01);
            envelopeMaterial.alphaMode = Engine.ALPHA_COMBINE;
            envelopeMaterial.hasAlpha = true;

            paraventMaterial.opacityTexture = textureParavent;
            paraventMaterial.diffuseTexture = textureParavent;
            paraventMaterial.diffuseTexture.hasAlpha = true;
            paraventMaterial.specularColor = new Color3(0.01, 0.01, 0.01);
            paraventMaterial.alphaMode = Engine.ALPHA_COMBINE;
            paraventMaterial.hasAlpha = true;

            if (modelUrl) {
                let modelPath = `${modelUrl.substring(0, modelUrl.lastIndexOf('/') + 1)}`
                let modelName = modelUrl.substring(modelUrl.lastIndexOf('/') + 1);

                const balloonTask = assetsManagerRef.current.addMeshTask("balloonTask", "", modelPath, modelName);
                balloonTask.onSuccess = (task) => {
                    const modelElementsArray = [];
                    currentModel.current = new Mesh("BalloonModel", sceneRef.current);
                    task.loadedMeshes.forEach((mesh) => {
                        mesh.setParent(currentModel.current);
                        let clonedMaterial = material.clone();
                        const match = getCoordinatesFromId(mesh.name);
                        if (match) {

                            while (modelElementsArray.length <= match.row) {
                                modelElementsArray.push([]);
                            }
                            while (modelElementsArray[match.row].length <= match.col) {
                                modelElementsArray[match.row].push(null)
                            }
                            mesh.material = clonedMaterial;

                            mesh.material.alpha = 1;

                            modelElementsArray[match.row][match.col] = mesh;
                        } else if (mesh.name === "KOLECKO") {
                            mesh.material = clonedMaterial
                            mesh.material.alpha = 1;
                            setITop(mesh);
                        } else if (mesh.name === "OBAL") {
                            mesh.material = envelopeMaterial;
                            mesh.material.alpha = 1;
                            setIEnvelope(mesh)
                        } else if (mesh.name === "VENTIL") {
                            mesh.material = paraventMaterial;
                            mesh.material.alpha = 1;
                            setIParavent(mesh)
                        } else if (mesh.name === "SCOOP") {
                            mesh.material = clonedMaterial;
                            mesh.material.alpha = 1;
                            setIScoop(mesh)
                        } else {

                        }

                    });
                    const modelScaling = 100 / getParentSize(currentModel.current).y
                    currentModel.current.scaling = new Vector3(modelScaling, modelScaling, modelScaling);
                    setIEnvelopeGrid(modelElementsArray);
                }
                balloonTask.onError = (task, message, exception) => {
                    console.error(message, exception);
                };
                assetsManagerRef.current.useDefaultLoadingScreen = false;
                assetsManagerRef.current.onTasksDoneObservable.add(function (task) {
                    setModelLoaded(true);

                })
                assetsManagerRef.current.onTaskErrorObservable.add(function (task, message, exception) {
                    console.error(message, exception);
                });
                assetsManagerRef.current.load();
            }
        }

    }, [modelUrl, sceneReady])


    useEffect(() => {
        if (sceneReady && selectedScene) {
            const skyboxMaterial = sceneRef.current.getMeshByName("skyBox").material;
            if (skyboxMaterial.diffuseTexture)
                skyboxMaterial.diffuseTexture.dispose();
            if (skyboxMaterial.emissiveTexture)
                skyboxMaterial.emissiveTexture.dispose();
            if (selectedScene.background) {
                try {
                    const texture = new Texture(
                        selectedScene.background,
                        sceneRef.current, true, false, Texture.TRILINEAR_SAMPLINGMODE);

                    skyboxMaterial.diffuseTexture = texture;
                    skyboxMaterial.diffuseTexture.coordinatesMode = Texture.FIXED_EQUIRECTANGULAR_MIRRORED_MODE;
                    skyboxMaterial.emissiveTexture = texture;
                    skyboxMaterial.emissiveTexture.coordinatesMode = Texture.FIXED_EQUIRECTANGULAR_MIRRORED_MODE;
                    skyboxMaterial.emissiveColor = new Color3(1, 1, 1);

                } catch (error) {
                    console.log(error)
                    skyboxMaterial.diffuseColor = Color3.FromHexString(selectedScene.color);
                    skyboxMaterial.diffuseTexture = null;
                    skyboxMaterial.emissiveColor = Color3.FromHexString(selectedScene.color);
                    skyboxMaterial.emissiveTexture = null;
                }
            }
            else {
                skyboxMaterial.diffuseColor = Color3.FromHexString(selectedScene.color);
                skyboxMaterial.diffuseTexture = null;
                skyboxMaterial.emissiveColor = Color3.FromHexString(selectedScene.color);
                skyboxMaterial.emissiveTexture = null;
            }
        }
    }, [selectedScene, sceneReady]);

    useEffect(() => {
        setModelLoaded(false);
    }, [])

    const onSceneReady = (scene) => {

        scene.clearColor = new Color4(1, 1, 1, 1);
        const camera = new ArcRotateCamera("Camera", -Math.PI / 2, Math.PI / 2, 150, Vector3.Zero(), sceneRef.current);
        camera.maxZ = 10000;
        camera.setTarget(Vector3.Zero());
        camera.lowerRadiusLimit = 80;
        camera.upperRadiusLimit = 395;
        canvasRef.current = sceneRef.current.getEngine().getRenderingCanvas();
        camera.attachControl(canvasRef.current, true);
        const ambientLight = new HemisphericLight("ambientLight", new Vector3(1, 0, 0.3), sceneRef.current);
        ambientLight.groundColor = new Color3(0.9, 0.9, 0.9);
        ambientLight.intensity = 0.98;
        const engine = scene.getEngine();
        engine.setHardwareScalingLevel(1.0 / window.devicePixelRatio)

        const skyboxMaterial = new StandardMaterial("skyBox", sceneRef.current);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.disableLighting = true;

        const skybox = MeshBuilder.CreateSphere("skyBox", { diameter: 800, segments: 32, sideOrientation: Mesh.BACKSIDE }, sceneRef.current);
        skybox.material = skyboxMaterial;

        assetsManagerRef.current = new AssetsManager(sceneRef.current);
        setSceneReady(true);
    };

    /**
     * Will run on every frame render.
     */
    const onRender = (scene) => {
        if (currentModel.current) {
            const time = performance.now() / 1000;
            const bounceHeight = 2.5;
            const bounceSpeed = 0.5;
            currentModel.current.position.y = Math.sin(time * bounceSpeed) * bounceHeight;
        }
    };
    return (
        <SceneComponent
            sceneRef={sceneRef}
            antialias="false"
            onSceneReady={onSceneReady}
            onRender={onRender}
            style={{ flex: 1, width: "100%", display: viewMode === 'Grid' ? 'none' : 'block', overflow: "hidden", objectFit: "contain" }}
            id="designer-view" />
    )
};

export default React.forwardRef(DesignerView);