import React from 'react';
import { icons } from '../../icons';


function LoadingScreen() {
    return (
        <div className="loading-screen bg-[var(--k-100-f0f0f0)]">
            <div className="loading-container">
                <icons.companyLogo alt="Company Logo" style={{ position: 'absolute', width: '75%', height: '75%' }} />
                <div className="loading-trail"></div>
            </div>
        </div>
    );
}

export default LoadingScreen;