import React, { useState, useEffect, useRef } from "react";
import { parse } from 'svg-parser';
import './App.css';
import TopBar from './Components/TopBar/TopBar';
import ToolBar from './Components/ToolBar/ToolBar';
import DesignerView from "./Components/Designer/DesignerView";
import CanvasContainer from "./Components/Canvas/CanvasContainer";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";


function App() {
  // --- State Variables ---
  const [selectedTool, setSelectedTool] = useState("Panel");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedNomexColor, setSelectedNomexColor] = useState(null);
  const [canvasState, setCanvasState] = useState(null);
  const [envelopesData, setEnvelopesData] = useState(null);
  const [fabricsData, setFabricsData] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModelUrl, setSelectedModelUrl] = useState(null);
  const [selectedModelGrid, setSelectedModelGrid] = useState(null);
  const [selectedFabric, setSelectedFabric] = useState(null);
  const [showContextToolBar, setShowContextToolBar] = useState(true);
  const [scenes, setScenes] = useState([])
  const [selectedScene, setSelectedScene] = useState(null);
  const [viewMode, setViewMode] = useState("Split");
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [envelopeDimension, setEnvelopeDimension] = useState(null);
  const [settings, setSettings] = useState(null);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [paraventSize, setParaventSize] = useState(null);
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const canvasRef = useRef(null);
  const designerRef = useRef(null);
  const isDevelopment = process.env.NODE_ENV !== 'production';

  const BACKEND_BASE_URL = isDevelopment
    ? '/kreatura/designer-api'
    : 'https://kubicekballoons.com/en/designer-api';


  // Function to load an image as a base64 data URL
  const loadImageAsBase64 = (imageUrl) => {
    return fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  };


  // --- Data Fetching ---
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const fetchData = async () => {
      try {
        const fetchPromises = [
          fetch(`${BACKEND_BASE_URL}/envelope`),
          fetch(`${BACKEND_BASE_URL}/fabrics`),
          fetch(`${BACKEND_BASE_URL}/settings`),
          fetch(`${BACKEND_BASE_URL}/scenes`)
        ];


        if (id) {
          fetchPromises.push(fetch(`${BACKEND_BASE_URL}/design/${id}`));
        }

        const responses = await Promise.all(fetchPromises);

        if (responses.some(response => !response.ok)) {
          throw new Error('Failed to fetch data');
        }

        const [envelopes, fabrics, settings, scenes, design] = await Promise.all(responses.map(response => response.json()));

        setEnvelopesData(envelopes);
        setFabricsData(fabrics);
        setScenes(scenes.items);
        setSelectedScene(scenes.items.find(scene => scene.id === scenes.default));
        setSettings(settings);

        if (design) {
          let selectedFabric = fabrics.items.find((fabric) => fabric.id === design.design.selectedFabricID)
          const hexToIdMap = {};
          selectedFabric.colors.items.forEach(color => {
            hexToIdMap[color.id] = color.hex;
          });

          const nomexHexToIdMap = {};
          selectedFabric.nomex.items.forEach(color => {
            nomexHexToIdMap[color.id] = color.hex;
          });

          let canvasState = JSON.parse(design.design.canvasState)
          const modifiedEnvelopeGrid = canvasState.envelopeGrid.map((row, idx) => {
            if (idx === 0) {
              return row.map(id => nomexHexToIdMap[id] || id)
            } else {
              return row.map(id => hexToIdMap[id] || id)
            }
          }
          );

          const imagePromises = canvasState.images.map(async (image) => {
            try {
              const base64Data = await loadImageAsBase64(settings.filesBaseUrl + image.src);
              return { ...image, src: base64Data };
            } catch (error) {
              console.error(`Error loading image ${image.src}:`, error);
              return image;
            }
          });

          const paraventPromise = async () => {
            try {
              const base64Data = await loadImageAsBase64(settings.filesBaseUrl + canvasState.paraventLogo.src);
              return { ...canvasState.paraventLogo, src: base64Data };
            } catch (error) {
              console.error(`Error loading paravent ${canvasState.paraventLogo.src}:`, error);
              return canvasState.paraventLogo;
            }
          };
          let updatedImages = [];
          let updatedParaventLogo = null;
          if (canvasState.images) {
            updatedImages = await Promise.all(imagePromises);
          }
          if (canvasState.paraventLogo) {
            updatedParaventLogo = await paraventPromise();
          }

          setSelectedModel(envelopes.items.find((envelope) => envelope.id === design.design.selectedModelID));
          setSelectedFabric(selectedFabric);
          setCanvasState({ ...canvasState, envelopeGrid: modifiedEnvelopeGrid, images: updatedImages, paraventLogo: updatedParaventLogo });
          setSelectedNomexColor(canvasState.nomexColor);
          const defaultColor = selectedFabric.colors.items.find(
            color => color.id === selectedFabric.colors.defaultPaint
          );
          if (!selectedColor) {
            setSelectedColor(defaultColor);
          }
        } else {
          let selectedFabric = fabrics.items.find((fabric) => fabric.id === fabrics.default)
          setSelectedModel(envelopes.items.find((envelope) => envelope.id === envelopes.default))
          setSelectedFabric(selectedFabric)

          const defaultColor = selectedFabric.colors.items.find(
            color => color.id === selectedFabric.colors.defaultPaint
          );
          const defaultNomexColor = selectedFabric.nomex.items.find(
            color => color.id === selectedFabric.nomex.defaultPaint
          );

          if (!selectedColor) {
            setSelectedColor(defaultColor);
          }
          if (!selectedNomexColor) {
            setSelectedNomexColor(defaultNomexColor);
          }

        }
        setInitialFetchComplete(true);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

  }, []);


  function flattenSVG(svgString) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString.trim(), "image/svg+xml");
    const newSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const originalSvg = svgDoc.querySelector('svg');
    for (let i = 0; i < originalSvg.attributes.length; i++) {
      newSvg.setAttribute(originalSvg.attributes[i].name, originalSvg.attributes[i].value);
    }

    svgDoc.querySelectorAll('g').forEach(group => {
      if (group.id.substring(0, 3) === "pol") {
        Array.from(group.children).forEach(element => {
          const secondThirdChars = element.id.substring(1, 3);
          const polNumber = group.id.substring(3)
          element.setAttribute('id', secondThirdChars + "-" + polNumber)
          element.removeAttribute('style')
          newSvg.appendChild(element);
        });
      }
    });
    const serializer = new XMLSerializer();
    const modifiedSVGString = serializer.serializeToString(newSvg);

    return modifiedSVGString;
  }

  useEffect(() => {
    if (selectedModel) {
      const fetchData = async () => {
        try {
          const response = await fetch(BACKEND_BASE_URL + selectedModel.data.split('api')[1]);
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data = await response.json();
          let svgPath = data.resources.svgPath;
          fetch(svgPath).then(response => response.text()).then(
            svgText => {
              setSelectedModelGrid(parse(flattenSVG(svgText)).children[0]);
            }
          )
          setSelectedModelUrl(data.resources.modelPath)
          let paraventSize = data.resources.paraventSize;
          setParaventSize(paraventSize);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    }
  }, [selectedModel]);


  const doReset = (model = selectedModel) => {
    setCanvasState(null);
    if (model !== selectedModel) {
      setSelectedModel(model)
    } else {
      setSelectedModelGrid({ ...selectedModelGrid });
    }

  }

  const undo = () => {
    canvasRef.current.undo();
  };

  const redo = () => {
    canvasRef.current.redo();
  };


  // --- JSX Rendering ---
  return (
    <div className="flex flex-col items-center w-full h-full bg-[var(--k-100-f0f0f0)] font-[var(--unnamed-font-family-nexa)]">
      {initialFetchComplete &&
        <>
          {envelopesData && fabricsData &&
            <TopBar
              modelOptions={envelopesData}
              fabricOptions={fabricsData}
              selectedFabric={selectedFabric}
              selectedModel={selectedModel}
              onSetModel={(newModel) => {
                doReset(newModel);
              }}
              onSetFabric={setSelectedFabric}
              onReset={doReset}
              canvasState={canvasState}
              settings={settings}
              onUndo={undo}
              onRedo={redo}
              history={history}
              redoStack={redoStack}
              viewMode={viewMode}
              setViewMode={setViewMode}
              scenes={scenes}
              selectedScene={selectedScene}
              setSelectedScene={setSelectedScene}
              designerRef={designerRef}
              canvasRef={canvasRef}
            />}
          <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'end', alignItems: 'center', overflow: 'hidden' }}>
            {!modelLoaded && <LoadingScreen />}

            {canvasState && selectedScene && viewMode !== "Grid" && (
              <DesignerView
                modelUrl={selectedModelUrl}
                canvasState={canvasState}
                viewMode={viewMode}
                selectedScene={selectedScene}
                envelopeDimension={envelopeDimension}
                settings={settings}
                setModelLoaded={setModelLoaded}
                ref={designerRef}
              />
            )}

            {selectedModelGrid && selectedFabric && paraventSize &&
              <CanvasContainer
                svgContent={selectedModelGrid}
                selectedTool={selectedTool}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                selectedNomexColor={selectedNomexColor}
                setSelectedNomexColor={setSelectedNomexColor}
                showContextToolBar={showContextToolBar}
                setShowContextToolBar={setShowContextToolBar}
                setSelectedTool={setSelectedTool}
                canvasState={canvasState}
                setEnvelopeDimension={setEnvelopeDimension}
                setCanvasState={setCanvasState}
                defaultColor={selectedFabric.colors.items.find(
                  color => color.id === selectedFabric.colors.default
                )}
                defaultNomexColor={selectedFabric.nomex.items.find(
                  color => color.id === selectedFabric.nomex.default
                )}
                history={history}
                setHistory={setHistory}
                redoStack={redoStack}
                setRedoStack={setRedoStack}
                canvasRef={canvasRef}
                viewMode={viewMode}
                scenes={scenes}
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
                selectedFabric={selectedFabric}
                paraventSize={paraventSize}
              />}
          </div>
          {selectedColor && viewMode !== "Envelope" && (
            <ToolBar
              selectedTool={selectedTool}
              selectedColor={selectedColor}
              showContextToolBar={showContextToolBar}
              onShowContextToolBar={setShowContextToolBar}
              onToolChange={(newTool) => {
                setShowContextToolBar(true);
                setSelectedTool(newTool);
              }}
            />
          )}
        </>}

    </div>
  );

}

export default App;
