import { useEffect, useRef } from "react";
import { Engine, Scene, WebGPUEngine } from "@babylonjs/core";

const SceneComponent = ({ sceneRef, antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, ...rest }) => {
  const reactCanvas = useRef(null);

  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    let observer = null;
    let resize = null;

    const createEngine = async () => {
      if (navigator.gpu && WebGPUEngine.IsSupportedAsync) {
        try {
          const isSupported = await WebGPUEngine.IsSupportedAsync;
          if (isSupported && false) { //TODO unblock webgpu
            console.log("Using WebGPU engine");
            const engine = new WebGPUEngine(canvas, antialias, engineOptions, adaptToDeviceRatio);
            // engine.
            await engine.initAsync();
            return engine;
          } else {
            console.warn("WebGPU support check failed unexpectedly.");
          }
        } catch (e) {
          console.warn("Error checking WebGPU support:", e);
        }
      }
      console.log("Using WebGL engine");
      return new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
    };
    const initializeScene = async () => {
      const engine = await createEngine();

      sceneRef.current = new Scene(engine, sceneOptions);


      engine.runRenderLoop(() => {
        if (typeof onRender === "function") onRender(sceneRef.current);
        sceneRef.current.getEngine().resize(true);
        sceneRef.current.render();
      });
      const resize = () => {
        sceneRef.current.getEngine().resize(true);
      };

      if (sceneRef.current.isReady()) {
        onSceneReady(sceneRef.current);
      } else {
        sceneRef.current.onReadyObservable.addOnce((scene) => onSceneReady(scene));
      }
    };

    initializeScene();

    return () => {
      if (sceneRef.current) {
        sceneRef.current.getEngine().dispose();
      }
    };
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions,
  ]);

  return <canvas ref={reactCanvas} {...rest} />;
};

export default SceneComponent;