import { ReactComponent as CompanyLogo } from './Assets/Icons/Homepage K-symbol link.svg';
import { ReactComponent as CompanyName } from './Assets/Icons/K Balloons.svg';
import { ReactComponent as BackIkonB } from './Assets/Icons/Black/kb-designer-ico-positive-button-action-history.svg';
import { ReactComponent as ShareIkonB } from './Assets/Icons/Black/kb-designer-ico-positive-button-action-share.svg';
import { ReactComponent as ContactIkonB } from './Assets/Icons/Black/kb-designer-ico-positive-button-action-send.svg';
import { ReactComponent as ResetModelIkonB } from './Assets/Icons/Black/kb-designer-ico-positive-new-model-40.svg';
import { ReactComponent as SendAction } from './Assets/Icons/White/kb-designer-ico-inverse-button-action-send.svg'
import { ReactComponent as ShareAction } from './Assets/Icons/White/kb-designer-ico-inverse-button-action-share.svg'
import { ReactComponent as ViewModeEnvelope } from './Assets/Icons/Black/kb-designer-ico-positive-viewmode-envelope-40.svg'
import { ReactComponent as ViewModeSplit } from './Assets/Icons/Black/kb-designer-ico-positive-viewmode-split-40.svg'
import { ReactComponent as ViewModeGrid } from './Assets/Icons/Black/kb-designer-ico-positive-viewmode-table-40.svg'
import { ReactComponent as ViewModeFullScreen } from './Assets/Icons/Black/kb-designer-ico-positive-viewmode-fullscreen-40.svg'
import { ReactComponent as ViewModeNormScreen } from './Assets/Icons/Black/kb-designer-ico-positive-viewmode-normscreen-40.svg'
import { ReactComponent as BalloonPanelB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-panel.svg'
import { ReactComponent as BalloonRowB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-row.svg'
import { ReactComponent as BalloonGoreB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-gore.svg'
import { ReactComponent as BalloonEnvelopeB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-all.svg'
import { ReactComponent as BalloonDiagonalUpB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-diagonal-ascend.svg'
import { ReactComponent as BalloonDiagonalDownB } from './Assets/Icons/Black/kb-designer-ico-positive-envelope-diagonal-descend.svg'
import { ReactComponent as BalloonParachuteB } from './Assets/Icons/Black/kb-designer-ico-positive-delete.svg'
import { ReactComponent as BalloonNomexB } from './Assets/Icons/Black/kb-designer-ico-positive-colour-nomex.svg'
import { ReactComponent as ColorPickerB } from './Assets/Icons/Black/kb-designer-ico-positive-colour-scale.svg'
import { ReactComponent as SwitchColorB } from './Assets/Icons/Black/kb-designer-ico-positive-color-change.svg'
import { ReactComponent as BackgroundB } from './Assets/Icons/Black/kb-designer-ico-positive-background.svg'
import { ReactComponent as AddImgB } from './Assets/Icons/Black/kb-designer-ico-positive-add-img.svg'
import { ReactComponent as AddTextB } from './Assets/Icons/Black/kb-designer-ico-positive-add-tetx.svg'
import { ReactComponent as CloseIconB } from './Assets/Icons/Black/kb-designer-ico-positive-balloon-button-close.svg'
import { ReactComponent as AddIconB } from './Assets/Icons/Black/kb-designer-ico-positive-add.svg'
import { ReactComponent as CloneIconB } from './Assets/Icons/Black/kb-designer-ico-positive-clone.svg'
import { ReactComponent as RemoveIconB } from './Assets/Icons/Black/kb-designer-ico-positive-remove.svg'
import { ReactComponent as RollIconB } from './Assets/Icons/Black/kb-designer-ico-positive-button-action-roll.svg'
import { ReactComponent as BoldIconB } from './Assets/Icons/Black/kb-designer-ico-positive-button-typo_bold-24.svg'
import { ReactComponent as ItalicIconB } from './Assets/Icons/Black/kb-designer-ico-positive-button-typo_italic-24.svg'
import { ReactComponent as ScenesIconB } from './Assets/Icons/Black/kb-designer-ico-positive-scene-40.svg'
import { ReactComponent as RedoIconB } from './Assets/Icons/Black/kb-designer-ico-positive-history_redo_40.svg'
import { ReactComponent as UndoIconB } from './Assets/Icons/Black/kb-designer-ico-positive-history_undo_40.svg'
import { ReactComponent as HamburgeIconrB } from './Assets/Icons/Black/kb-designer-ico-positive-hamburger_40.svg'

export const icons = {
  companyName: CompanyName,
  companyLogo: CompanyLogo,
};
export const black_icons = {
  backIkonB: BackIkonB,
  shareIkonB: ShareIkonB,
  contactIkonB: ContactIkonB,
  resetModelIkonB: ResetModelIkonB,
  viewModeEnvelope: ViewModeEnvelope,
  viewModeSplit: ViewModeSplit,
  viewModeGrid: ViewModeGrid,
  viewModeFullScreen: ViewModeFullScreen,
  viewModeNormScreen: ViewModeNormScreen,
  balloonPanel: BalloonPanelB,
  balloonRow: BalloonRowB,
  balloonGore: BalloonGoreB,
  balloonEnvelope: BalloonEnvelopeB,
  balloonDiagonalUp: BalloonDiagonalUpB,
  balloonDiagonalDown: BalloonDiagonalDownB,
  balloonParachute: BalloonParachuteB,
  balloonNomex: BalloonNomexB,
  colorPicker: ColorPickerB,
  switchColor: SwitchColorB,
  background: BackgroundB,
  addImg: AddImgB,
  addText: AddTextB,
  closeIcon: CloseIconB,
  addIcon: AddIconB,
  cloneIcon: CloneIconB,
  removeIcon: RemoveIconB,
  rollIcon: RollIconB,
  boldIcon: BoldIconB,
  italicIcon: ItalicIconB,
  scenesIcon: ScenesIconB,
  redoIconB: RedoIconB,
  undoIconB: UndoIconB,
  hamburgeIconrB: HamburgeIconrB
};
export const white_icons = {
  sendAction: SendAction,
  shareAction: ShareAction
};