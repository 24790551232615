import React, { useState, useEffect } from 'react';
import { black_icons } from '../../icons';
import SceneSelector from '../SceneSelector/SceneSelector';


const FloatingToolBar = ({ viewMode, onViewModeChange, scenes, selectedScene, setSelectedScene }) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isSceneSelectorOpen, setIsSceneSelectorOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerHeight < 600 || window.innerWidth < 600);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(window.innerHeight > 600);



    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(document.fullscreenElement !== null);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerHeight < 600 || window.innerWidth < 600);
            setIsMobileMenuOpen(window.innerHeight > 500);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="floating-bar flex flex-col items-center bg-[var(--k-100-f0f0f0)]">
            {isMobileView &&
                <button className="mode-btn" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <black_icons.hamburgeIconrB alt="Hamburger Menu" />
                </button>}
            {isMobileMenuOpen && <>
                <div className='flex flex-col items-center justify-center'>
                    <button className={`mode-btn`} onClick={() => { setIsSceneSelectorOpen(true) }}>
                        <black_icons.scenesIcon alt="Scenes View" />
                    </button>
                    <p>SCENE</p>
                </div>
                <button className={`mode-btn ${viewMode === 'Envelope' ? 'active' : ''}`} onClick={() => onViewModeChange("Envelope")}>
                    <black_icons.viewModeEnvelope alt="Envelope View" />
                </button>
                <button className={`mode-btn ${viewMode === 'Split' ? 'active' : ''}`} onClick={() => onViewModeChange("Split")}>
                    <black_icons.viewModeSplit alt="Split View" />
                </button>
                <button className={`mode-btn ${viewMode === 'Grid' ? 'active' : ''}`} onClick={() => onViewModeChange("Grid")}>
                    <black_icons.viewModeGrid alt="Grid View" />
                </button>
                <button className="mode-btn" id="full-screen-toggle" onClick={toggleFullScreen}>
                    {isFullScreen ? <black_icons.viewModeNormScreen alt="Normal Screen View" /> : <black_icons.viewModeFullScreen alt="Full Screen View" />}
                </button>
            </>}
            {scenes && <SceneSelector show={isSceneSelectorOpen} onHide={() => setIsSceneSelectorOpen(false)} selectedScene={selectedScene} setSelectedScene={setSelectedScene} scenes={scenes} />}
        </div>
    );
};

export default FloatingToolBar;