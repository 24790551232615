import React, { useEffect, useState } from 'react';

const SceneSelector = ({ show, onHide, selectedScene, setSelectedScene, scenes, }) => {
    const [selectedSceneId, setSelectedSceneId] = useState(null);

    useEffect(() => {
        if (selectedScene) {
            setSelectedSceneId(selectedScene.id);
        }
    }, [selectedScene]);

    const handleSceneSelect = (sceneId) => {
        setSelectedSceneId(sceneId);
    };

    const handleConfirm = () => {
        setSelectedScene(scenes.filter(scene => scene.id === selectedSceneId)[0]);
        onHide();
    };

    return (
        <div className="mdl backdrop-blur-md" style={{ display: show ? 'flex' : 'none' }}>
            <div className="mdl-dia">
                <div className="scene-list">
                    {scenes.map((scene) => (
                        <div
                            key={scene.id}
                            className={`image-option ${scene.id === selectedSceneId ? 'selected' : ''
                                }`}
                            style={{
                                backgroundImage: scene.thumbnail ? `url(${scene.thumbnail})` : null,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundColor: scene.thumbnail ? null : scene.color,
                            }}
                            onClick={() => handleSceneSelect(scene.id)}
                        >
                        </div>
                    ))}
                </div>

                <div className="mdl-row">
                    <button type="button" className="mdl-btn primary" onClick={handleConfirm} disabled={!selectedSceneId}>
                        Confirm
                    </button>
                    <button type="button" className="mdl-btn secondary" onClick={onHide}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SceneSelector;
