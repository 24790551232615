import React, { useState, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { black_icons } from '../../icons';
import Select, { components } from 'react-select';


function ContextToolBar({
  selectedTool, selectedColor,
  setSelectedColor, selectedNomexColor,
  setSelectedNomexColor,
  selectedFabric,
  canvasRef, onClose, setShowModal
}) {
  const [currentHoverColor, setCurrentHoverColor] = useState(null);
  const [curentTextStyle, setCurentTextStyle] = useState({});
  const [fontsLoaded, setFontsLoaded] = useState({});


  const loadFont = (newTextStyle) => {
    if (!fontsLoaded[newTextStyle.fontFamily + newTextStyle.fontWeight + newTextStyle.fontStyle]) {
      const font = new FontFaceObserver(newTextStyle.fontFamily, { weight: newTextStyle.fontWeight, style: newTextStyle.fontStyle });
      font.load().then(() => {
        setFontsLoaded(prevFonts => ({ ...prevFonts, [newTextStyle.fontFamily + newTextStyle.fontWeight + newTextStyle.fontStyle]: true }));
        setCurentTextStyle(newTextStyle);
      });
    } else {
      setCurentTextStyle(newTextStyle)
    }
  };
  useEffect(() => {
    loadFont({ fontFamily: 'Roboto', fontWeight: 'normal', fontStyle: 'normal', fill: '#000000' });
  }, []);

  const handleCurrentTextStyleChange = (newTextStyle) => {
    loadFont(newTextStyle);
  };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.updateText(curentTextStyle)
    }
  }, [curentTextStyle])

  useEffect(() => {
    if (selectedTool === "Paravent") {
      setShowModal(true);
    }
  }, [selectedTool])


  const handleAddClick = () => {
    if (selectedTool === "Text") {
      canvasRef.current.addText(curentTextStyle);
    }
    else {
      setShowModal(true);
    }
  };

  const handleRemoveClick = () => {
    if (selectedTool === "Paravent") {
      canvasRef.current.removeLogo();
    } else {
      canvasRef.current.removeObject();
    }
  }

  const handleCloneClick = () => {
    canvasRef.current.cloneObject();
  }

  const Placeholder = (props) => (
    <components.Placeholder {...props}>
      Choose
    </components.Placeholder>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '24px',
      height: '24px',
      width: '100%',
      fontSize: '13px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '24px',
      width: '100%',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '24px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(---ffffff-white)',
      color: state.isSelected ? 'var(--kb-500-0084cc)' : 'var(--k-900-303030)',
      ':hover': {
        backgroundColor: 'var(---ffffff-white)',
        color: 'var(---e6ab00-hover-color)'
      },
      fontSize: '13px',
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: '200px',
      overflowY: 'auto',
      fontSize: '13px',
      scroll: 'auto'
    }),
  };

  const fontFamilyOptions = [
    { value: 'BalsamicSans', label: 'Balsamic Sans' },
    { value: 'ChakraPetch', label: 'Cahkra Petch' },
    { value: 'CrimsonText', label: 'CrimsonText' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Merriweather', label: 'Merriweather' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'ZillaSlab', label: 'Zilla Slab' }
  ];

  return (
    <div className="context-tool-bar"
      style={
        { position: 'absolute', bottom: 0, maxWidth: '100vw', width: 'max(772px, 100%)' }
      }
    >
      <div className='tool-grid'>
        {["Panel", "Row", "Gore", "Envelope", "SpiralUp", "Spiral", "Swap"].includes(selectedTool) &&
          selectedFabric.colors.items.map((colorOption) => (
            <div
              key={colorOption.id}
              className={`color-option ${colorOption === selectedColor ? 'selected' : ''}`}
              style={{ backgroundColor: colorOption.hex }}
              onClick={() => setSelectedColor(colorOption)}
              onMouseEnter={() => setCurrentHoverColor(colorOption)}
              onMouseLeave={() => setCurrentHoverColor(null)}
            >
              {currentHoverColor === colorOption && (
                <div className="color-tooltip">{currentHoverColor.name}</div>
              )}
            </div>
          ))
        }{["Nomex"].includes(selectedTool) &&
          selectedFabric.nomex.items.map((colorOption) => (
            <div
              key={colorOption.id}
              className={`color-option ${colorOption === selectedNomexColor ? 'selected' : ''}`}
              style={{ backgroundColor: colorOption.hex }}
              onClick={() => {
                setSelectedNomexColor(prevColor => colorOption);
              }

              }
              onMouseEnter={() => setCurrentHoverColor(colorOption)}
              onMouseLeave={() => setCurrentHoverColor(null)}
            >
              {currentHoverColor === colorOption && (
                <div className="color-tooltip">{currentHoverColor.name}</div>
              )}
            </div>
          ))
        }{
          ["Graphic", "Text", "Paravent"].includes(selectedTool) &&
          <>
            <button className="context-toolbar-button" onClick={handleAddClick}><black_icons.addIcon /><p>ADD</p></button>
            {selectedTool !== "Paravent" &&
              <button className="context-toolbar-button" onClick={handleCloneClick}><black_icons.cloneIcon /><p>CLONE</p></button>}
            <button className="context-toolbar-button" onClick={handleRemoveClick}><black_icons.removeIcon /><p>REMOVE</p></button>
            {selectedTool === "Text" &&
              <>
                <hr className="separator" />
                <div className='context-toolbar-button'>
                  <Select
                    id="fontFamilySelect"
                    options={fontFamilyOptions}
                    value={fontFamilyOptions.find(option => option.value === curentTextStyle.fontFamily)}
                    onChange={(selectedOption) => handleCurrentTextStyleChange({ ...curentTextStyle, fontFamily: selectedOption.value })}
                    components={{ Placeholder }}
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPlacement="top"
                  />
                  <p>FONT FAMILY</p>
                </div >
                <button className={`context-toolbar-button ${curentTextStyle.fontWeight === 'bold' ? 'toggle' : ''}`} onClick={() => handleCurrentTextStyleChange({ ...curentTextStyle, fontWeight: curentTextStyle.fontWeight === 'bold' ? 'normal' : 'bold' })}><black_icons.boldIcon /><p>BOLD</p></button>
                <button className={`context-toolbar-button ${curentTextStyle.fontStyle === 'italic' ? 'toggle' : ''}`} onClick={() => handleCurrentTextStyleChange({ ...curentTextStyle, fontStyle: curentTextStyle.fontStyle === 'italic' ? 'normal' : 'italic' })}><black_icons.italicIcon /><p>ITALIC</p></button>
                <div className='context-toolbar-button'>
                  <input type="color" id="color" value={curentTextStyle.fill} onChange={(e) => handleCurrentTextStyleChange({ ...curentTextStyle, fill: e.target.value })} />
                  <p>SWATCH</p>
                </div>
              </>}
            {selectedTool !== "Paravent" &&
              <button className="context-toolbar-button" onClick={canvasRef.current.bringForward}>
                <span style={{ display: 'inline-block', transform: 'rotate(90deg)' }}>
                  <black_icons.undoIconB />
                </span>
                <p>Forward</p></button>
            }
            {selectedTool !== "Paravent" &&
              <button className="context-toolbar-button" onClick={canvasRef.current.bringBackward}>
                <span style={{ display: 'inline-block', transform: 'rotate(90deg)' }}>
                  <black_icons.redoIconB />
                </span>
                <p>Back</p>
              </button>}
          </>
        }
      </div>

      <button className="close-button" onClick={onClose}><black_icons.closeIcon /></button>
    </div>
  );
}

export default ContextToolBar;